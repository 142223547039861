@import '../../assets/scss/variable';
@import '../../assets/scss/mixins';
@import '../../assets/scss/config';

// filter: blur(5px);

.header_container {
  max-width: 100%;
  height: 60px;
  background-color: $bleu-bgcolor;
  z-index: 10;
}

.header-scrolled {
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem;
  transition: $lg-transition;
  background-color: rgba(16, 16, 70, 0.87);

  &.colorChange {
    background-color: $bleu-bgcolor;
  }

  .logo {
    z-index: 3;
    width: 50px;
    // height: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .navbar {
    height: 100%;
  }

  .hamburger-lines {
    @include d-flex(flex-end, center);
  }

  button {
    color: $header-color;
    text-align: center;

    .burger_class {
      font-size: 25px;
    }
  }

  .nav_menu {
    justify-content: stretch;
    padding-top: 6rem;
    position: fixed;
    top: 60px;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    overflow-y: auto;
    transform: translateX(100%);
    transition: 0.7s ease;

    &.show {
      // right: 0;
      background-color: $bleu-bgcolor;
      transform: translateX(0%);
      transition: 0.7s ease;
    }

    li {
      max-width: 200px;
      margin: 0.5rem auto;
      font-size: 12px;
      // text-transform: uppercase;
      overflow: hidden;
      position: relative;

      a {
        color: $header-color;
        // width: 100%;
        height: 100%;
        padding: 0.8rem 0.5rem;
        display: block;

        @include d-flex(space-between, center);

        @extend %btn-clicked;

        &.active {
          color: $secondary-color;
        }
      }
    }

    .nav_auth {
      width: 100%;
      align-items: center;

      li {
        text-align: center;

        &:first-child {
          text-decoration: underline;
        }

        &.sign-up {
          white-space: nowrap;
          // width: 100%;

          a {
            @include d-flex(center, center);

            width: 120px;
            // text-align: center;
            padding: 10px;
            color: #fff;
            background-color: $secondary-color;
            border: 2px solid $secondary-color;
            border-radius: 12px;
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            -ms-border-radius: 12px;
            -o-border-radius: 12px;

            &:hover {
              color: $secondary-color;
              border: 2px solid $secondary-color;
              background-color: $bleu-bgcolor;
            }
          }
        }
      }
    }
  }
}

@include media(480px) {
  .header-scrolled {
    padding: 0 7%;

    .logo {
      width: 60px;
      // height: 45px;
    }

    .hamburger-lines button .burger_class {
      font-size: 30px;
    }

    .nav_menu {
      & li {
        font-size: 14px;
      }
    }
  }
}

@include media(768px) {
  .header_container {
    height: 70px;
  }

  .header-scrolled {
    height: 70px;

    .logo {
      width: 65px;
    }

    .hamburger-lines button .burger_class {
      font-size: 35px;
    }

    .nav_menu {
      top: 70px;

      & li {
        font-size: 16px;
      }
    }
  }
}

@include media(992px) {
  .header-scrolled {
    padding: 0 5%;
    height: 70px;

    @include d-flex(space-between, center);

    .logo {
      width: 70px;
    }

    .hamburger-lines {
      display: none;
    }

    .navbar {
      width: 70%;

      @include d-flex(flex-end, center);
    }

    .nav_menu {
      position: static;
      width: 100%;
      height: 100%;
      padding-top: 0;
      background-color: transparent;
      transform: translateX(0%);

      @include d-flex(flex-end, center);

      flex-direction: row;

      li {
        width: 100%;
        // max-width: 70px;
        margin: 0 5px;
        padding: 0;
        white-space: nowrap;
        font-size: 14px;

        a {
          width: auto;
          padding: 8px;
          background-color: transparent;
          display: block;
          text-align: center;
          color: $header-color;
        }

        span {
          display: none;
        }
      }

      .nav_auth {
        flex-direction: row;
        justify-content: flex-end;
        padding-left: 12%;

        li {
          &.sign-up {
            min-width: 100px;

            a {
              padding: 7px;
              width: 100%;
              border-radius: 10px;
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              -ms-border-radius: 10px;
              -o-border-radius: 10px;
            }
          }
        }
      }
    }
  }
}

@include media(1200px) {
  .header-scrolled {
    padding: 0 3%;

    .logo {
      width: 75px;
    }

    .navbar {
      width: 70%;

      li {
        max-width: 100px;
      }
    }

    .nav_menu {
      .nav_auth {
        padding-left: 15%;
      }
    }
  }
}

@include media(1480px) {
  .header-scrolled {
    padding: 0 5%;

    .logo {
      width: 80px;
    }

    .nav_menu {
      li {
        font-size: 16px;
        max-width: 100px;
      }

      .nav_auth {
        padding-left: 20%;
      }
    }
  }
}
