@import './variable';
@import './mixins';

%active-menu {
  color: $secondary-color;
}

%active-page {
  background-color: $secondary-color;
}

%btn-clicked {
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $secondary-color;
  }
}

%btn-hovered {
  background-color: $secondary-color;

  &:hover {
    color: $secondary-color;
  }
}

.d-flex {
  display: flex;
}

.flex-center {
  justify-content: space-between;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.text-center {
  text-align: center;
}
