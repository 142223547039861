$primary-color: #b1b1c6;
$secondary-color: darkturquoise;

$text-color: #777;
$title-color: #4f4f4f;
$header-color: #fff;
$white: rgb(255, 255, 255);

$bleu-bgcolor : #060651;
$sm-backg: #77777746;

$xs-family: "Poppins",sans-serif;
$lg-transition: all 3.5s ease-in-out;
$sm-transition: all 3s ease-in-out;

// screen sizes
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
