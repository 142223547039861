@import './variable';
@import './mixins';

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

// html {
//   scroll-behavior: smooth;
//     font-size: 10px; // 10px
//     line-height: 16px;
// }

// body {
//   width: 100%;
//   color: $text-color;
//   font-family: $xs-family;
//   font-size: 1.6rem;
//   line-height: 1.625rem;
//   behavior: smooth;
// }

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: $text-color;
  box-shadow: inset 0 0 5px $text-color;
  border-radius: 100vw;
  // margin-block: 0.2rem;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient($secondary-color, $bleu-bgcolor);
  border-radius: 100vw;
  height: 40vh; //for vertical scrollbar
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient($bleu-bgcolor, $secondary-color, $secondary-color);
}

@include media(480px) {
  html {
    font-size: 13px;
    line-height: 16px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
}

@include media(768px) {
  html {
    font-size: 14px;
    line-height: 19px;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }
}

@include media(992px) {
  html {
    font-size: 14px;
    line-height: 18px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

@include media(992px) {
  html {
    font-size: 16px;
    line-height: 22px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
