@import '../../assets/scss/variable';
@import '../../assets/scss/mixins';
@import '../../assets/scss/config';

.footer_section {
  width: 100%;
  margin: 0;
  margin-top: auto;
  z-index: 10;
}

.footer_one {
  width: 100%;
  // margin-top: 4rem;
  padding: 4rem 1rem;
  background-color: $bleu-bgcolor;
}

.footer_lists {
  width: 100%;
  margin: 0 auto;

  @include d-flex(center, center);

  flex-flow: wrap;
  gap: 1rem;

  & > li {
    min-width: 250px;
    width: 100%;
    max-width: 400px;
    font-size: 11px;
    // margin: 0.5rem 1rem;
    padding: 0 1rem;
    font-weight: 400;

    h3 {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 1rem;
      color: $header-color;

      &.connect {
        margin-bottom: 1rem;
      }
    }

    .item_content {
      p {
        @include d-flex(flex-start, flex-start);

        column-gap: 4px;
        margin: 0.5rem 0;
        font-size: 10px;
        font-weight: 300;
        line-height: 1.825em;
        color: $header-color;

        &.contact_info > svg {
          margin-top: 7px;
          color: $text-color;
        }
      }

      p > a {
        @include d-flex(flex-start, center);

        color: $text-color;

        &.item_link:hover {
          @extend %btn-clicked;

          svg {
            @extend %btn-clicked;
          }
        }
      }

      svg {
        font-size: 1rem;
        color: $text-color;
        margin-right: 0.6rem;
      }
    }

    .social_media_wrapper {
      @include d-flex(flex-start, center);

      a svg {
        color: $header-color;
        background-color: $title-color;
        margin: 0 0.2rem 0.8rem;
        padding: 0.15rem;
        font-size: 1rem;
        border-radius: 50%;
        transition: all 0.3s ease;
        box-shadow: 0 11px 32px -12px $text-color;

        &:hover {
          background-color: $secondary-color;
          color: $header-color;
        }
      }
    }
  }
}

.footer_copy_right {
  border-top: $text-color 1px solid;
  background-color: $bleu-bgcolor;
  width: 100%;
  padding: 1rem 0;
  color: $header-color;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  position: relative;

  @include d-flex(space-between, center);
  // background-size: cover;
  // background-repeat: no-repeat;

  p {
    width: 100%;

    @include d-flex(center, center);

    flex-direction: column;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    padding: 0.5rem;

    span,
    span a {
      padding: 2px;
      display: flex;
      flex-wrap: nowrap;
      text-decoration: none;
    }

    svg {
      transform: translateY(3px);
      font-size: 0.8rem;
      margin: 0 3px;

      &:hover {
        color: $secondary-color;
      }
    }

    a {
      color: $header-color;
      font-weight: 700;
      text-decoration: underline;
    }

    .logo_icon {
      width: 40px;
      transform: translateY(-3px);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@include media(480px) {
  .footer_one {
    // padding: 4rem 1rem 5rem;
  }

  .footer_lists {
    @include d-flex(space-around, flex-start);

    flex-flow: row wrap;
    width: 100%;

    & > li {
      padding: 0;
      width: 45vw;
      max-width: 280px;
      min-height: 150px;

      h3 {
        font-size: 15px;
        margin-bottom: 1.5rem;
      }

      .item_content p {
        font-size: 11px;
      }

      .social_media_wrapper {
        @include d-flex(flex-start, center);

        a svg {
          margin-top: 0.275rem;
          padding: 0.2rem;
          font-size: 1.2rem;
        }
      }
    }
  }

  .footer_copy_right {
    height: 70px;
    // background-color: aqua;

    p {
      flex-flow: row wrap;
      font-size: 13px;

      span.span {
        transform: translateY(7px);
      }
    }
  }
}

@include media(768px) {
  .footer_one {
    padding: 4rem 3% 5rem;
  }

  .footer_lists {
    gap: 2rem;

    & > li {
      max-width: 250px;

      h3 {
        font-size: 16px;
      }

      .item_content p {
        font-size: 11.5px;

        &.about-parag {
          line-height: 1.7rem;
        }
      }

      width: 30vw;
    }
  }

  .footer_copy_right {
    // width: 80%;
    // max-width: 992px;

    p {
      font-size: 14px;

      .logo_icon {
        width: 50px;
        transform: translateY(-7px);
      }
    }
  }
}

@include media(992px) {
  .footer_one {
    // padding: 4rem 3% 5rem;
  }

  .footer_lists {
    width: 100%;
    flex-flow: row nowrap;
    gap: 5%;

    & > li {
      min-width: 120px;
      width: 25vw;
      max-width: 220px;

      h3 {
        font-size: 17px;
      }

      .item_content p {
        font-size: 12px;
      }

      &:first-child {
        max-width: 280px;
      }

      &:nth-child(2) {
        max-width: 150px;
      }

      .social_media_wrapper {
        a svg {
          margin: 0.275rem 0.3rem;
          padding: 0.25rem;
          font-size: 1.3rem;
        }
      }
    }
  }
}

@include media(1200px) {
  .footer_one {
    padding: 4rem 5% 5rem;
  }

  .footer_lists {
    gap: 3%;

    & > li {
      max-width: 250px;
      width: 40vw;

      h3 {
        font-size: 18px;
      }

      .item_content p {
        // font-size: 14px;
      }

      &:first-child {
        max-width: 280px;
      }

      &:nth-child(2) {
        max-width: 180px;
      }

      .social_media_wrapper {
        a svg {
          margin: 0.275rem 0.3rem;
          padding: 0.3rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}
