.loader-cont {
  background-color: #060651;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 130px;
  background: transparent;
  border: 3px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 130px;
  font-family: sans-serif;
  font-size: 15px;
  color: #fff;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.ring::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  border-radius: 50%;
  animation: animateC 1.5s linear infinite;
}

.ring-span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 1.5s linear infinite;
}

.ring-span::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ff241e;
  top: -6px;
  right: -8px;
  box-shadow: 0 0 20px #ff241e;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}
